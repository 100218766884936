<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'"> {{ $t("message.model") }}</p>
          <el-button type="warning" @click="closeModal()"> {{ $t("message.close") }}</el-button>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadStaff">
        <el-row :gutter="20">
          <el-col
            :span="24" >
            <div class="timeline-items__right rounded-sm w-100 p-4"   :class="mode ? 'table__myday' : 'table__mynight'">
              <div class="timeline-items__right user rounded-sm w-100 p-4" v-if="attendance_info&&attendance_info.staff"  @dblclick="pushToProfile(attendance_info.staff)" :class="mode ? 'table__myday' : 'table__mynight'">
                <div class="info-user-left3" :class="mode ? '' : 'performance__itemnight'">
                  <div class="img">
                    <img
                      :src="
                        attendance_info.staff.image
                          ? baseUrl + attendance_info.staff.image.path
                          : 'img/avatar.jpeg'
                      "
                      alt=""
                    />
                  </div>
                  <div class="name-info-user-left">
                    <b>{{ $t('message.staff') }}</b>
                    <div class="name_u">
                    <b>{{ $t('message.fio') }}: </b>{{ attendance_info.staff.name + " " + attendance_info.staff.last_name }}
                    </div>
                    <div class="company_u" v-if="attendance_info.staff.company" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.company') }}: </b> {{ attendance_info.staff.company.name }}
                    </div>
                    <div class="company_u" v-if="attendance_info.staff.branch" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.branch') }}: </b> {{ attendance_info.staff.branch.name }}
                    </div>
                    <div class="company_u" v-if="attendance_info.staff.department" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.department') }}: </b>  {{ attendance_info.staff.department.name }}
                    </div>
                    <div class="company_u" v-if="attendance_info.staff.position" :class="mode ? 'text__day2' : 'text__night2'">
                      <b>{{ $t('message.position') }}: </b>{{ attendance_info.staff.position.name }}
                    </div>
                    <!-- <div class="salary">
                      <div><h4>Зарплата</h4></div>
                      <div>
                        <ul class="salary-list">
                          <li
                            v-for="(value, index) in staff.salary_amounts"
                            :key="'salary-' + index"
                          >
                            {{ value.toLocaleString() }} {{ index }}
                          </li>
                        </ul>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div v-if="attendance_info&&attendance_info.reports">
                <hr>
                Ishlagan soati: {{attendance_info.reports.total_working_hour ?(parseInt(attendance_info.reports.total_working_hour ) +$t('message.hour') +' '+ ((attendance_info.reports.total_working_hour - parseInt(attendance_info.reports.total_working_hour )) *60).toFixed(0)+$t('message.minut') ):0 }}
                <br>
                Online soati: {{ attendance_info.reports.online_work_hour?(parseInt(attendance_info.reports.online_work_hour) +$t('message.hour') +' '+ ((attendance_info.reports.online_work_hour-parseInt(attendance_info.reports.online_work_hour)) *60).toFixed(0)+$t('message.minut') ):0 }}
                <br>
                <!-- Qo'shimcha ish soati: {{  attendance_info.reports.additional_work_hour?(parseInt(attendance_info.reports.additional_work_hour) +$t('message.hour') +' '+ ((attendance_info.reports.additional_work_hour-parseInt(attendance_info.reports.additional_work_hour)) *60).toFixed(0)+$t('message.minut') ):0  }} -->
              </div>
              </div>
            <div class="timeline-items__right rounded-sm w-100 p-4"  :class="mode ? 'table__myday' : 'table__mynight'">
             <div class="title_and_button mb-3">
                <h4 class="m-0 ">Журнал</h4>
                <el-button :size="'small'" type="success"  @click="drawerCreate = true">{{$t('message.create')}}</el-button>
             </div>
              <el-row :gutter="20">
                 <el-col :span="24">
                    <table
                       class="table-my-code table-bordered td-center"
                       :class="mode ? 'table__myday' : 'table__mynight'"
                       v-loading="loadStaff"
                     >
                       <thead>
                         <tr>
                           <th >
                              Фото 
                           </th>
                           <th>
                            Имя
                           </th>
                           <th >
                            Филиалы
                           </th>
                           <th>
                            Тип посещаемости
                           </th>
                           <th >
                            Время
                           </th>
                           <th >
                            Устройство
                           </th>
                           <th >
                            Дата создания
                           </th>
                         </tr>
                       </thead>
                       <tbody>
                         <tr v-for="att in list" :key="'atts-' + att.id">
                           <td :class="att.device?'':(mode ? 'td-warning' : '')">
                             <el-image
                               style="width: 40px; height: 40px"
                               :src="
                                 att.captured_img
                                   ? baseUrl + att.captured_img
                                   : 'img/avatar.jpeg'
                               "
                               >
                               <!-- :preview-src-list="previewList" -->
                             </el-image>
                           </td>
                            <td :class="att.device?'':(mode ? 'td-warning' : '')">
                               {{
                                 att.staff ? att.staff.name + " " + att.staff.last_name : ""
                               }}
                             </td>
                              <td :class="att.device?'':(mode ? 'td-warning' : '')" >
                                 {{
                                   att.staff
                                     ? att.staff.branch
                                       ? att.staff.branch.name
                                       : ""
                                     : ""
                                 }}
                               </td>
                           <td :class="att.device?'':(mode ? 'td-warning' : '')">{{ att.punch_state }}</td>
                           <td :class="mode ? 'td-success' : ''" >
                             <el-button type="success" size="mini" icon="el-icon-time" round>
                               {{ att.punch_time }}
                             </el-button>
                           </td>
                           <td :class="att.device?'':(mode ? 'td-warning' : '')">{{ att.device?att.device.device_name: $t('message.manual') }}</td>
                           <!-- <td >{{ att.device && att.device.device_name ? att.device.device_name : '' }}</td> -->
                           <td :class="att.device?'':(mode ? 'td-warning' : '')">{{ att.created_at }}</td>
                         </tr>
                       </tbody>
                     </table>
                 </el-col>
               </el-row>
           </div>
          </el-col>
        </el-row>
      </div>
      <!-- end app-modal__body -->
    </div>
    <el-dialog
        :title="$t('message.new_m',{
               m: $t('message.journal')})"
        :visible.sync="drawerCreate"
        width="40%"
        :append-to-body="true"
        center
        ref="drawerCreate"
        :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      >
      <crm-create
          ref="drawerCreateChild"
          drawer="drawerCreate"
      ></crm-create>
      </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";
import CrmCreate from "./crm-create";
export default {
  name: "show-info",
  mixins: [drawer],
  components: {CrmCreate},
  data() {
    return {
      drawerCreate:false,
      loadStaff: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
    };
  },

  computed: {
    ...mapGetters({
      attendance_info: "attendanceReport/attendance_info",
      list: "attendanceReport/attendance_report",
      
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      getInfoList: "attendanceReport/getInfo",
      getOneStaffOneDayAttendanceInfo: "attendanceReport/getOneStaffOneDayAttendance",
    }),
    opened(child) {
      if (!this.loadStaff) {
        this.loadStaff = true;
        this.getInfoList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
        this.getOneStaffOneDayAttendanceInfo(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal() {
      this.drawerCreate =false;
      this.$emit("closeInfoModal", false);
    },
    closeDialogModalNew() {
      this.drawerCreate =false;
    },
  },
};
</script>
<style lang="scss" scoped>
.timeline-items__right.user {
  margin-bottom: 20px;
  .company_u{
    margin-bottom: 3px;
    font-size: 14px;
  }
  .info-user-left3 {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .img {
      margin-right: 10px;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 10%;
      }
    }
  }
  .name_u {
    font-weight: bold;
    font-size: 15px;
  }
  .position_u {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }
  .salary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
}
.title_and_button{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
